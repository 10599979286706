<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Usuário"
          class="px-5 py-3"
        >

          <v-form class="pt-1" @submit.prevent="postUser">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Nome"
            >
              <v-text-field
                label="Nome"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="name"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Email"
            >

              <v-text-field
                
                label="Email"
                filled
                readonly
                class="ml-4"
                :error-messages="errors"
                v-model="email"
              />

            </validation-provider>

              <v-text-field
                type="password"
                label="Senha"
                outlined
                v-model="password"
                class="ml-4"
              />


            <v-card-actions class="pl-0">
              <v-btn
                color="success"
                min-width="100"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UsersService from '@/services/UsersService'

  export default {
    name: 'Profile',

    components: { 

    },

    data: () => ({
      name: "",
      email: "",
      password: "",
      id: ""
    }),

    mounted () {
      this.getUser()
    },

    methods: {

      postUser() {
        let data = []

        data['name']      =  this.name
        data['email']     =  this.email
        if(this.password != ""){
          data['password']  =  this.password
        }

        UsersService.postUser(data,this.id)
        .then((response) => {
          console.log(response)
          if(response.data.message == "Erro"){
            throw 500
          }
          this.$toast.success('Perfil atualizado.', '',{position:'topRight'})
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao salvar.', '',{position:'topRight'})
        })
      },

      getUser() {
        UsersService.getUser(JSON.parse(localStorage.getItem('user')).id)
        .then(response => {
          this.id =  response.data.user.id,
          this.name = response.data.user.name,
          this.email = response.data.user.email,
          this.password = response.data.user.password
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao recuperar Usuário.', '',{position:'topRight'})
        })
      },
    },
  }
</script>